<template>
  <div class="special-offer__right flex items-stretch">
    <h3 class="text-base leading-60px text-white font-sans tracking-normal font-bold pl-8.5" v-if="item.title">{{ item.title }}</h3>
    <div class="flex justify-center items-center">
      <sb-render
        :key="_item._uid"
        v-for="(_item, index) in item.offers.slice(0, 2)"
        class="flex-1 w-5/12"
        :class="{ 'special-offer--second' : index == 1, 'special-offer--first' : index == 0 }"
        :item="_item" />
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'SpecialOfferItemRight',
  extends: Blok
}
</script>

<style lang="scss" scoped>
  @special-offer: ~'.special-offer';
  .special-offer {
    $self: &;
    &--even {
      #{ $self }__right {
        @apply bg-black;
      }
    }
    &--odd {
      #{ $self }__right {
        @apply bg-dark-5;
      }
    }
  }
</style>
